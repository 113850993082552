@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  color: rgb(0, 0, 0);
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;

}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-around;
  height: [auto];
  padding: 0rem 1rem 1rem 1rem;
  border-bottom: 1px rgba(255, 255, 255,0.4) solid;
  margin-bottom: 0.2rem;
  width: 100%;
}

.react-calendar__navigation span {
  min-width: 44px;
  background: none;
  font-size: x-large;
}

.react-calendar__navigation button {
  font-size: xx-large;
}

.react-calendar__navigation button:disabled {
opacity: 90%
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  border-bottom: 1px solid white;
  
}



.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  color: rgb(0, 0, 0);
  
}

.react-calendar__month-view__days__day--weekend {
  color: rgb(0, 0, 0);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #838b9a;
  opacity: 70%;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
  
}

.react-calendar__tile {
  max-width: 100%;
  padding: 5px 3.4px;
  background: none;
  text-align: center;
  line-height: 36px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  filter: drop-shadow(1px 1px 1px #F2F2F2);
  border-left: 1px solid white;  
}

.react-calendar__tile:disabled {
  color: rgba(255,255,255,0.2);
  opacity: 50%;
  border-left: none;
  border-right: none;
}



.react-calendar__tile--now {
  border-left: 1px solid white;
  
}

/* .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-color: #F2B23F;
  color: #f2B23F
} */

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  color: #F2B23F;
  border-color: #F2B23F;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  color: #F2B23F;
}

/* .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
} */


.paginate .number::before{
  content: "Page ";
}

.engineerCards li:nth-child(-n+3){
  z-index: 1;
  filter: drop-shadow(0 4px 2px #212020)
}
.engineerCards li:nth-child(4){
  transform: translate(0px, -10px)
}
.engineerCards li:nth-child(5){
  transform: translate(0px, -10px)
}
.engineerCards li:nth-child(6){
  transform: translate(0px, -10px)
}

.roomCards li:nth-child(1){
  z-index: 1;
  filter: drop-shadow(0 4px 2px #212020)
}

.roomCards li:nth-child(2){
  transform: translate(0px, -10px)
}

.roomCards li:nth-child(3){
  transform: translate(0px, -17px)
}

.formBackground {
  background-image: url("constants/phoenixwhite.png"),
  radial-gradient(white,#F2F2F2);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid black;
}

.iconBackground {
  background-image: url("constants/phoenixblack.png"),
  radial-gradient(circle at center, rgba(84, 13, 110, 0.10), rgba(84, 13, 110, 0.70) 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}




.navPhoneInput {
    border: 1px rgb(255, 255, 255) solid;
    border-radius: .5rem;
    padding: 6px 4px;
    background-color: #212020;
  }

  .PhoneInputCountrySelect option{
  background-color: #212020;
  }


.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 72px;
  height: 36px;
  padding: 0;
  border-radius: 30px;
  background-color: #ffffff;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 2px #ffffff solid;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgb(33 32 32 / 0.4);
  --tw-shadow: var(--tw-shadow-colored);
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #212020;
}

.react-toggle--checked .react-toggle-track {
  background-color: #212020;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #212020;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 3px;
  width: 32px;
  height: 32px;
  border: 2px solid #000000;
  border-radius: 50%;
  background-color: #ffffff;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 40px;
  border-color: #2B242E;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.simplybook-widget-button {
  position: fixed;
  display: hidden;
  padding: 15px;
  font-size: 1.2em;
  cursor: pointer;
  z-index: 9999;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.35);
}

.simplybook-widget-button:hover div {
  margin-bottom: 5px;
}

.simplybook-widget-button.right {
  right: 0px;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
  border-radius: 5px 5px 0 0;
}

.simplybook-widget-button.left {
  left: 0px;
  transform: rotate(90deg);
  transform-origin: 0% 100%;
  border-radius: 5px 5px 0 0;
}

.simplybook-widget-button.top {
  top: 0px;
  border-radius: 0 0 5px 5px;
}

.simplybook-widget-button.bottom {
  bottom: 0px;
  border-radius: 5px 5px 0 0;
}

.simplybook-widget-container {
  position: relative;
  top: 20px;
  z-index: 9999;
  display: block;
  width: 100%;
  max-width: 768px;
  max-height: 740px;
  max-height: 90vh;
  bottom: 65px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* .simplybook-widget-container {
  display: none;
} */

.simplybook-widget-container iframe {
  width: 100%;
  height: 100%;
}

iframe.sb-widget-iframe {
  min-width: 100%;
  max-width: 100%;
  width: 0;
  height: 100%;
  background-color: transparent;
}

.simplybook-widget-overlay {
  background-color: #212020;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: block;
  opacity: 0;
  z-index: 9950;
  transition: all 0.5s;
}

.simplybook-widget-overlay.active {
  opacity: 0.5;
}


iframe.sb-widget-iframe header {
  display: hidden;
}

.formBackground {
  background-image: url("constants/phoenixwhite.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.iconBackground {
  background-image: url("constants/phoenixblack.png"),
  radial-gradient(circle at center, rgba(84, 13, 110, 0.10), rgba(84, 13, 110, 0.70) 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.thumbnailBackground{
  background-image: url("constants/phoenixwhite.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.heroImage{
  background-image:url("constants/aroom.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

